import {fetch} from "@/utils/request.js";

function getCacheCat() {
  try {
    if (sessionStorage.getItem("cat")) {
      return JSON.parse(sessionStorage.getItem("cat"))
    }
  } catch (e) {
    // todo
  }
  return []
}


function setCacheCat(cat_type, value) {
  let data = getCacheCat()
  let index = data.findIndex(item => item.cat_type === cat_type)
  if (index !== -1) {
    data.splice(index,1)
  } else {
    data.push(value)
  }
  sessionStorage.setItem("cat", JSON.stringify(data))
}


async function getCatList(cat_type_list, noCache = false) {
  const cacheCat = getCacheCat()
  // 结果
  let result = []
  // 需要请求的cat_type
  let fetchCatType = []

  if (!noCache) {
    for (let item of cat_type_list) {
      let find = cacheCat.find(cat => cat.cat_type === item.toUpperCase() && cat.expire > Date.now())
      if (find) {
        // console.log(`命中缓存 cat_type: ${find.cat_type}`)
        result.push(find)
      } else {
        fetchCatType.push(item)
      }
    }
  }

  if (fetchCatType.length > 0) {
    await fetch("post", "/cpy/cat/bycattype", {cat_type: fetchCatType.join(",")}).then(res => {
      if (res.status === 0) {
        for (let item of res.data) {
          // console.log(`请求成功 cat_type: ${item.cat_type}`)
          item.cat_type = item.cat_type.toUpperCase()
          let expire = Date.now() + (1000 * 60 * 12)
          result.push({...item, expire})
          setCacheCat(item.cat_type, {...item, expire })
        }
      } else {
        console.error("获取基础数据接口错误 start：",res.message)
        console.log("--- response message:", res.message)
        console.log("--- request data:", {cat_type: fetchCatType.join(",")})
        console.error("获取基础数据接口错误 end;")
        return Promise.reject()
      }
    }).catch(() => {})
  }
  return result
}

export default {
  data() {
    return {
      _last_cat: "",
      // 如果设置为true则不读取缓存每次都请求接口
      noCache: false,
      cat: {}
    }
  },
  methods: {
    // 根据基础数据的code查询对应的name 前提是this.cat中有定义对应的type
    getCatNameByCode(cat_code, cat_type) {
      if (cat_type in this.cat) {
        let find = this.cat[cat_type].find(item => item.cat_code === cat_code)
        if (find) {
          return find.cat_name
        }
      }
      return undefined
    },
    afterGetCat() {},
    // 获取单个基础数据数组
    getOneCat(cat_type) {
      return getCatList([cat_type]).then(res => {
        return res[0]?.list
      })
    },
    getCatList() {
      if (Object.keys(this.cat).length === 0) return;
      getCatList(Object.keys(this.cat), this.noCache).then(async data => {
        let catTypeList= Object.keys(this.cat)
        for (let key in catTypeList) {
          let catType = catTypeList[key]
          let find = data.find(val => val.cat_type.toUpperCase() === catType.toUpperCase())
          if (find) {
            this.cat[catType] = find.list
          } else {
            console.warn(`cat_type: ${catType} 未查询到`)
          }
        }
        this.afterGetCat()
      }).catch(e => {
        console.log(".getCatList().catch", e)
      })
    },
    // 手动刷新某个type的基础数据
    refreshCat(type) {
      const cacheCat = getCacheCat()
      let index = cacheCat.findIndex(item => item.cat_type === type.toUpperCase())
      if (index !== -1) {
        cacheCat.splice(index, 1)
        this.getCatList()
      }
    },
  },
  created() {
    this.getCatList()
  },
}
