<template>
  <div class="app-main-content">
    <card title="项目财务">
      <echarts :option="charsOptions" width="100%" height="600px"></echarts>
    </card>
  </div>
</template>

<script>
import {fetch} from "@/utils/request.js";
import dayjs from "dayjs";
import catMixin from "@/utils/mixins/catMixin.js";
import Echarts from "@/components/echarts.vue";

export default {
  components: { Echarts },
  mixins: [catMixin],
  data() {
    return {
      cat: {
        money_tags: []
      },
      charsData: []
    }
  },
  computed: {
    charsOptions() {
      return {
        title: {
          text: '财务报表',
          top: '13px',
          left: '15px',
          textStyle: {
            color: '#4D4D4D',
            fontSize: '18px',
            fontWeight: '500',
            fontFamily: 'SourceHanSans',
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: this.cat.money_tags.map(item => item.cat_name),
          icon:'rect',
          itemGap: 20, // 设置图例项之间的间隔距离
          itemWidth: 15, // 设置图例项的宽度
          itemHeight: 15, // 设置图例项的高度
          right:'400px',
          top: '55px'
        },
        grid: {
          top: '17%',
          left: '20px',
          right: '20%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.charsData.map(item => item.date),
            axisLabel: {
              textStyle: {
                color:'#000',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'SourceHanSans',
              }
            },
          }
        ],
        yAxis: [
          {
            name: "单位：万元",
            nameGap: 30,
            nameTextStyle: {
              padding: [0, 20, 0, 0],
              color:'#000',
              fontSize: '16px',
              fontWeight: '400',
              fontFamily: 'SourceHanSans',
            },
            type: 'value',
            axisLabel: {
              textStyle: {
                color:'#000',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'SourceHanSans',
              }
            },
          }
        ],
        series: this.cat.money_tags.map(item => {
          return {
            name: item.cat_name,
            type: "line",
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: this.charsData.map(dataItem => {
              return dataItem.data.find(data => data.label === item.cat_name).value
            })
          }
        })
      };
    }
  },
  methods: {
    afterGetCat() {
      this.getData()
    },
    handleRaw(list) {
      let result = []
      for (let listElement of list) {
        let date = dayjs(listElement.key, "YYYY-MM-DD").format("YYYY-MM")
        let resultIndex = result.findIndex(item => item.date === date)
        if (resultIndex === -1) {
          result.push({
            date,
            data: this.cat.money_tags.map(item => {
              return { label: item.cat_name, value: 0 }
            })
          })
          resultIndex = result.length - 1
        }
        for (let valueElement of listElement.value) {
          let dataIndex = result[resultIndex].data.findIndex(item => item.label === valueElement.value_name)
          if (dataIndex === -1) {
            result[resultIndex].data.push({
              label: valueElement.value_name,
              value: Number(valueElement.value)
            })
          } else {
            result[resultIndex].data[dataIndex].value += Number(valueElement.value)
          }
        }
      }
      this.charsData = result
    },

    getData() {
      let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/prj/moneylog/report" : "/prj/moneylog/report"
      fetch("post", url, {
        prjoid: this.$route.query.prj_oid
      }).then(res => {
        if (res.status === 0 || window.mock) {
          this.handleRaw(res.data.datas)
        }
      })
    }
  }
}
</script>
